<template>
    <div class="app-main__inner">
        <div class="container mb-3 dm-sans-font">
            <div class="flex flex-col md:flex-row justify-between align-items-center mb-3">
                <div>
                    <div class="flex justify-between align-items-center space-x-3 mb-3">
                        <span class="text-sm md:text-2xl font-semibold text-gray-900">{{ $t('invoice') }}</span>
                    </div>
                </div>
                <div class="flex flex-col md:flex-row w-11/12 md:w-auto justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xs">
                    <span @click="showTab(2)" class="text-xs h-10 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-l-md" :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">SLUTLEVERERADE</span>
                    <span @click="showTab(3)" class="text-xs h-10 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2" :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">OBETALDA</span>
                    <span @click="showTab(4)" class="text-xs h-10 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2" :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">SLUTBETALDA</span>
                    <span @click="showTab(5)" class="text-xs h-10 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-r-md whitespace-nowrap" :class="tab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">ICKE LEVERERADEE</span>
                </div>
            </div>

            <div class="row flex justify-end mx-1">
                <div class="col-md-12 flex justify-center md:justify-end mb-2 bg-white border rounded-md p-2">
                    <div class="flex flex-col md:flex-row justify-end align-items-center space-x-4 space-y-3 md:space-y-0">
                        <span class="">
                            <input type="text" @input="searchInputTrigger" class="flex justify-between align-items-center space-x-4 focus:outline-none bg-gray-200 border px-3 py-2 rounded-md text-gray-500" v-bind:placeholder="$t('search')">
                        </span>
                    </div>
                </div>
            </div>


            <div class="row">
                
                    <!-- <div class="filtering-above-table">
                        <div class="fl-pagecount">
                            <label for="pricerange">{{ $t('show')}}:</label>
                            <select v-model="limit" class="form-select" @change="shopResultNumber">
                                <option value="10" selected>10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>
                        </div>
                        <div class="fl-search">
                            <input type="text" @input="searchInputTrigger" class="form-control" v-bind:placeholder="$t('search')">
                        </div>
                    </div> -->
                
                <!-- <div class="flex flex-col md:flex-row justify-between align-items-center w-full">
                    <div>
                        <h2 class="page-title font-light">{{ $t('invoice') }}</h2>
                    </div>
                    <div class="flex space-x-2">
                        <div><button class="tab-btn rounded-t-lg" :class="tab === 2 ? 'active font-semibold' : 'font-light'" @click="showTab(2)">SLUTLEVERERADE</button></div>
                        <div><button class="tab-btn rounded-t-lg" :class="tab === 3 ? 'active font-semibold' : 'font-light'" @click="showTab(3)">OBETALDA</button></div>
                        <div><button class="tab-btn rounded-t-lg" :class="tab === 4 ? 'active font-semibold' : 'font-light'" @click="showTab(4)">SLUTBETALDA</button></div>
                        <div><button class="tab-btn rounded-t-lg" :class="tab === 5 ? 'active font-semibold' : 'font-light'" @click="showTab(5)">ICKE LEVERERADE</button></div>
                    </div>
                </div> -->
                <div class="col-md-12 mt-3"> <!--pl-5 pr-5-->
                    <div class="table-responsive rounded-lg bg-white border">
                        <table v-loading='loading' style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <!-- <th class="text-xs">{{ $t('customerName') }}</th> -->
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('customerName') }}<span><i @click="sortCustomerNameAsc" class="lnr-arrow-up cursor-pointer"></i><i @click="sortCustomerNameDesc" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('invoiceNumber') }}<span><i @click="sortInvoiceNumberAsc" class="lnr-arrow-up cursor-pointer"></i><i @click="sortInvoiceNumberDesc" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('invoiceDate') }}<span><i @click="sortInvoiceDateAsc" class="lnr-arrow-up cursor-pointer"></i><i @click="sortInvoiceDateDesc" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <!-- <th class="text-xs"><div class="d-flex justify-content-between"><span>{{ $t('invoiceStatus') }}</span></div></th> -->
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('invoiceStatus') }}<span><i @click="sortInvoiceStatusAsc" class="lnr-arrow-up cursor-pointer"></i><i @click="sortInvoiceStatusDesc" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between">{{ $t('invoiceSummary') }}<span><i @click="sortInvoiceSummaryAsc" class="lnr-arrow-up cursor-pointer"></i><i @click="sortInvoiceSummaryDesc" class="lnr-arrow-down cursor-pointer"></i></span></div></th>
                                    <th class="text-xs"><div class="d-flex justify-content-between"><span>{{ $t('reference') }}</span></div></th>
                                    <th class="text-xs">{{ $t('action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(invoice, i) in GET_INVOICE.data" :key="i">
                                    <td class="text-xs">{{ invoice.customer_name }}</td>
                                    <td class="text-xs">{{ invoice.document_number }}</td>
                                    <td class="text-xs">{{ invoice.order_date }} </td>
                                    <td class="text-xs">
                                        <span v-if="invoice.order_status === 'Hanteras'" class="p-1 bg-table-yellow rounded">{{ $t('status.processing') }}</span>
                                        <span v-if="invoice.order_status === 'Mottagen'" class="p-1 bg-table-blue rounded">{{ $t('status.received') }}</span>
                                        <span v-if="invoice.order_status === 'Levererad'" class="p-1 bg-table-green rounded">{{ $t('status.delivered') }}</span>
                                    </td>
                                    <td class="text-xs">{{ invoice.total_to_pay }} 
                                    </td>
                                    <td class="text-xs">
                                        {{ invoice.your_reference }}
                                    </td>
                                    <td>
                                        <el-tooltip :content="$t('download')">
                                            <DownloadTableManager :data='invoice' value='invoice' />
                                        </el-tooltip>
                                        <!-- <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                            <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span> -->
                                    </td>
                                </tr>
                                <!-- <tr v-if="history.Invoices !== undefined && history.Invoices.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="pagination-block" v-if="GET_INVOICE.data">
                        <paginate
                        :page-count="GET_INVOICE.total_pages"
                        :click-handler="changePageNumber"
                        prev-text="<"
                        next-text=">"
                        :container-class="'pagination'">
                        </paginate>
                    </div> -->
                    <div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
                        <div class="pagination-block" v-if="GET_INVOICE.data"> <!--GET_ARTICLES.total_pages--->
                            <paginate
                            :page-count="GET_INVOICE.total_pages"
                            :click-handler="changePageNumber"
                            prev-text="<"
                            next-text=">"
                            :container-class="'pagination space-x-2'">
                            </paginate>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1"></div> -->
            </div>
        </div>


        <!-- <el-dialog
        :visible.sync="paymentCardModal"
        width="40%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div id="card-element" class="col-md-12">

                </div>
            </div>
        </div>
        </el-dialog> -->

        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import debounce from 'lodash.debounce'
    import Paginate from 'vuejs-paginate'
    // import i18n from './../../plugins/i18n'
    import DownloadTableManager from './../../customComponent/DownloadTableManager'

    export default {
        name : 'order-page',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
            paginate : Paginate,
            DownloadTableManager
        },
        data () {
            return {
                PaymentType : null,
                billing : {
                    name : '',
                    company_id : '',
                    address : {
                        address_line_one : '',
                        zip_code : '',
                        city : '',
                        phone_mobile : '',
                    },
                    email : '',
                    invoiceEmail : ''
                },
                Orders : [],
                history : [],
                copyHistory : [],
                tab : 1,
                parPage: 10,
                currentPage: 1,
                updating : false,
                paymentCardModal : false,
                processingCard : false,
                processingInvoice : false,
                loading : true,
                limit : 10
            }
        },
        watch : {
            limit : function (newVal, oldVal) {
                this.limit = newVal
                window.localStorage.setItem('invoicenum', newVal)
            }
        },
        computed : {
            ...mapGetters({
                GET_INVOICE : 'portal/GET_INVOICE'
            })
        },
        mounted () {
            const num = window.localStorage.getItem('invoicenum')
            if (parseInt(num)) {
                this.limit = num
                this.getCompanyInvoice(parseInt(num))
            } else {
                window.localStorage.setItem('invoicenum', this.limit)
                this.getCompanyInvoice(this.limit)
            }
        },
        methods : {
            searchInputTrigger : debounce(function (event) {
                if (event.target.value !== '') {
                    this.filterSearch(event)
                } else {
                    this.$store.commit('portal/FILTER_INVOICE_ALL', null)
                }
            }, 500),
            filterSearch (event) {
                this.$store.commit('portal/FILTER_SEARCH_INVOICE', { value : event.target.value })
            },
            changePageNumber (number) {
                let page = ''
                if (this.status) {
                    page = this.$services.endpoints.COMPANY_INVOICE + `?is_invoice=True&page=${number}&status=${this.status}&limit=${this.limit}` 
                } else {
                    page = this.$services.endpoints.COMPANY_INVOICE + `?is_invoice=True&page=${number}&limit=${this.limit}`
                }
                this.$store.dispatch('customer/getCompanyInvoice', { page : page })
                .then(_ => {
                    //
                }).catch((err) => {
                    if (err.response.status === 401) {
                        this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
            getCompanyInvoice  (limit, status = null) {
                this.loading = true
                this.$store.dispatch('customer/getCompanyInvoice', { status : status, limit : limit })
                .then(_ => {
                    this.loading = false
                }).catch((err) => {
                    this.loading = false
                    if (err.response.status === 401) {
                        this.$store.commit('auth/CLEAR_AUTH_CUS', null)
                        window.Bus.$emit('sign-out')
                    }
                })
            },
            sortInvoiceStatusAsc () {
                this.$store.commit('portal/SORT_INVOICE_STATUS_ASC')
            },
            sortInvoiceStatusDesc () {
                this.$store.commit('portal/SORT_INVOICE_STATUS_DESC')
            },
            sortCustomerNameAsc () {
                this.$store.commit('portal/SORT_INVOICE_CUSTOMER_NAME_ASC')
            },
            sortCustomerNameDesc () {
                this.$store.commit('portal/SORT_INVOICE_CUSTOMER_NAME_DESC')
            },
            sortInvoiceNumberAsc () {
                this.$store.commit('portal/SORT_INVOICE_NUMBER_ASC')
            },
            sortInvoiceNumberDesc () {
                this.$store.commit('portal/SORT_INVOICE_NUMBER_DESC')
            },
            sortInvoiceDateAsc () {
                this.$store.commit('portal/SORT_INVOICE_DATE_ASC')
            },
            sortInvoiceDateDesc () {
                this.$store.commit('portal/SORT_INVOICE_DATE_DESC')
            },
            sortInvoiceSummaryAsc () {
                this.$store.commit('portal/SORT_INVOICE_SUMMARY_ASC')
            },
            sortInvoiceSummaryDesc () {
                this.$store.commit('portal/SORT_INVOICE_SUMMARY_DESC')
            },
            showTab (number) {
                this.tab = number
                // if (this.tab === 2) {
                //     this.status = 'DELIVERED'
                //     this.getCompanyInvoice(this.status)
                // } else if (this.tab === 3) {
                //     this.status = 'INVOICED'
                //     this.getCompanyInvoice(this.status)
                // } else if (this.tab === 4) {
                //     this.status = 'PAID'
                //     this.getCompanyInvoice(this.status)
                // }
                if (this.tab === 2) {
                    this.status = 'SLUTLEVERERADE'
                    this.getCompanyInvoice(this.limit, this.status)
                } else if (this.tab === 3) {
                    this.status = 'OBETALDA'
                    this.getCompanyInvoice(this.limit, this.status)
                } else if (this.tab === 4) {
                    this.status = 'SLUBETALDA'
                    this.getCompanyInvoice(this.limit, this.status)
                } else if (this.tab === 5) {
                    this.status = 'ICK'
                    this.getCompanyInvoice(this.limit, this.status)
                }
            },
            shopResultNumber (e) {
                this.limit = parseInt(e.target.value)
                this.getCompanyInvoice(parseInt(e.target.value))
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
    // font-size: 14px !important;
}
.form-group{
    margin-bottom: 0.4rem !important;
}
.form-input{
    font-size: 0.8rem !important;
    padding: 0.2rem 0.75rem !important;
}
.col-form-label{
    padding-bottom: unset !important;
    padding-top: 0.3rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
// .table td{
//     padding: 0.1rem 0.55rem !important;
// }
.form-control{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-select{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem 1.5rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.btn-border-all{
    border: 1px solid $theme-secondary-color !important;
    color: $theme-secondary-color !important;
}
.tab-btn.active{
    color: #181C4C !important;
    background-color: #fff !important;
}
.table tbody tr > td{
    font-size: 13px !important;
    color: #6F6F6F !important;
    padding: 0.6rem 0.55rem !important;
}
</style>